/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import templateOnlyComponent from '@ember/component/template-only';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type { Filter } from 'embercom/services/chart-builder-filter-service';
import type { LogicalFilterOperator } from '../filters';
import type { MetricPropertyDefinition } from 'embercom/objects/reporting/unified/properties/types';
import type { StickyFilter } from 'embercom/components/reporting/custom/chart-builder/filter-bar/filter-bar';
import type Range from 'embercom/models/reporting/range';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';

export interface Args {
  filterableMetricProperties: MetricPropertyDefinition[];
  customFilterArgs?: $TSFixMe;
  filters: Filter[];
  onFiltersChanged: (
    index: number,
    attribute: { id: string; field: string },
    filterValues: any,
    operator: any,
    chartSeries?: ChartSeries | null,
  ) => void;
  conversationAttributeDescriptors?: ConversationAttributeDescriptor[];
  loadKnownValuesSources?: string[];
  onFiltersCleared: (chartSeries: ChartSeries | null) => void;
  range: Range | null;
  isAddFilterDisabled?: boolean;
  applyLogicalFilterOperator?: (operator: LogicalFilterOperator) => void;
  stickyFilterTypes?: StickyFilter[];
  showDateOverwritten?: boolean;
  isDisabled?: boolean;
  reportInViewMode?: boolean;
  haveFiltersChanged?: boolean;
  resetFiltersInViewMode?: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const Filters = templateOnlyComponent<Signature>();
export default Filters;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::Filters': typeof Filters;
    'reporting/custom/report/filters': typeof Filters;
  }
}
