/* import __COLOCATED_TEMPLATE__ from './timezones-picker.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import type Report from 'embercom/models/reporting/custom/report';
import type CustomReportsService from 'embercom/services/custom-reports-service';

interface Signature {
  Args: {
    report: Report;
    isStaticReport?: boolean;
    updatedTimezone?: boolean;
  };
}

export default class TimezonesPicker extends Component<Signature> {
  @service declare appService: { app: { timezone: string } };
  @service declare customReportsService: CustomReportsService;
  @service declare intercomEventService: { trackAnalyticsEvent: Function };

  @tracked timezoneSelected: string | null = null;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.timezoneSelected = this.defaultTimezoneName ?? null;
  }

  get timezoneList() {
    return this.customReportsService.timezones();
  }

  @action
  updateTimezone(name: string): void {
    let value = this.getTimezoneValue(name);
    if (value) {
      this.args.report.applyTimeZone(value);
      if (this.args.isStaticReport) {
        localStorage.setItem(`${this.args.report.id}_timezone`, value);
      }
      this.timezoneSelected = name;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'timezone',
        timezone: name,
      });
    }
  }

  getTimezoneName(value: string): string | undefined {
    return this.timezoneList.find((tz) => tz.value === value)?.description;
  }

  getTimezoneValue(name: string): string | undefined {
    return this.timezoneList.find((tz) => tz.description === name)?.value;
  }

  get currentTimezoneValue(): string | undefined {
    return this.getTimezoneValue(this.timezoneSelected || '');
  }

  get timezones(): { text: string; value: string }[] {
    return this.timezoneList.map((tz) => {
      return {
        text: tz.description,
        value: tz.description,
      };
    });
  }

  get defaultTimezoneValue(): string {
    if (this.args.isStaticReport) {
      return (
        localStorage.getItem(`${this.args.report.id}_timezone`) ||
        this.args.report.timezone ||
        this.appService.app.timezone
      );
    }
    return this.args.report.timezone || this.appService.app.timezone;
  }

  get defaultTimezoneName(): string | undefined {
    return this.getTimezoneName(this.defaultTimezoneValue);
  }

  get shouldRenderTimezones(): boolean {
    let appTimezone = this.timezoneList.find((tz) => tz.value === this.appService.app.timezone);
    return isPresent(appTimezone);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::TimezonesPicker': any;
    'reporting/custom/report/timezones-picker': any;
  }
}
